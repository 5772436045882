import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  position: 'relative',  
  width: '100%',
  height: '100%'
};

export class MapContainer extends Component {
  render() {
    return (
      <Map google={this.props.google}
        zoom={15}
        style={mapStyles}
        initialCenter={
          {
            lat: 43.297769191836416,
            lng: 5.424275735786245 
          }
        }
      >
        <Marker
          title={'Cabinet de Kinésithérapie La Boiseraie.'}
          name={'La Boiseraie'}
          position={{lat: 43.297769191836416, lng: 5.424275735786245}} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC_CBDGWLcXKDXXtM8ntpaJ-Bkg9RXRMoc'
})(MapContainer);